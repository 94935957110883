import AOS from 'aos';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { confirmed, email, required } from 'vee-validate/dist/rules';
import Vue from 'vue';
import VueGoogleMaps from 'vue-googlemaps';
import VueI18n from 'vue-i18n';
import VueScrollTo from 'vue-scrollto';
import { store } from './store';

// mount aos to window
window.AOS = AOS;

require('./bootstrap-vue');

// custom components
import AddToBookmarks from "./AddToBookmarks";
import CityOptionsSlider from "./CityOptionsSlider";
import ContactSlideIn from "./ContactSlideIn";
import GoogleMaps from "./GoogleMaps";
import HeroImageSlider from "./HeroImageSlider";
import HeroSlider from "./HeroSlider";
import InquiryCallBackService from "./InquiryCallBackService";
import InquiryForm from "./InquiryForm";
import Modal from "./Modal.vue";
import ModalShare from "./ModalShare";
import NativeShare from "./NativeShare";
import NavbarSearch from "./NavbarSearch.vue";
import Navigation from "./Navigation";
import NewsletterForm from "./NewsletterForm";
import NewsletterSlide from "./NewsletterSlide.vue";
import PropertyFilter from "./PropertyFilter";
import PropertyGridWithFilter from "./PropertyGridWithFilter";
import PropertyItemSlider from "./PropertyItemSlider";
import PropertySearch from "./PropertySearch";
import ReferenceSlider from "./ReferenceSlider";
import SideButtons from './SideButtons';
import TeamGrid from "./TeamGrid";

require('./secretsales')


Vue.use(VueI18n);
Vue.use(VueGoogleMaps, {
    load: {
        apiKey: 'AIzaSyDLZz4vvZT116lqS99j9AwGtRa-mgaRayc',
        useBetaRenderer: true
    }
});
Vue.use(VueScrollTo, {
    duration: 1000,
    offset: -80,
    easing: 'ease-in-out'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('AddToBookmarks', AddToBookmarks);
Vue.component('GoogleMaps', GoogleMaps);
Vue.component('HeroSlider', HeroSlider);
Vue.component('PropertyItemSlider', PropertyItemSlider);
Vue.component('PropertySearch', PropertySearch);
Vue.component('PropertyGridWithFilter', PropertyGridWithFilter);
Vue.component('InquiryCallBackService', InquiryCallBackService);
Vue.component('InquiryForm', InquiryForm);
Vue.component('Navigation', Navigation);
Vue.component('SideButtons', SideButtons);
Vue.component('PropertyFilter', PropertyFilter);
Vue.component('ReferenceSlider', ReferenceSlider);
Vue.component('NativeShare', NativeShare);
Vue.component('TeamGrid', TeamGrid);
Vue.component('ModalShare', ModalShare);
Vue.component('NewsletterForm', NewsletterForm);
Vue.component('CityOptionsSlider', CityOptionsSlider);
Vue.component('HeroImageSlider', HeroImageSlider);
Vue.component('ContactSlideIn', ContactSlideIn)
Vue.component('NewsletterSlide', NewsletterSlide)
Vue.component('NavbarSearch', NavbarSearch)
Vue.component('ModalPopup', Modal);

export const i18n = new VueI18n({
    locale: document.documentElement.lang,
    messages: window.translations,
    silentTranslationWarn: process.env.NODE_ENV === 'production'
});

const body = document.getElementsByTagName('body')[0];

// No message specified.
extend('email', {
    ...email,
    message: i18n.t('site.form.invalid_email')
});

// Override the default message.
extend('required', {
    ...required,
    message: i18n.t('site.form.required_message')
});
extend('confirmed', {
    ...confirmed,
    message: i18n.t('site.form.not_matching')
});

if (document.getElementById('app').className !== 'editmode') {
    const app = new Vue({
        store,
        i18n,
        mounted() {
            this.$nextTick(() => {
                if (!window.loaded) {
                    window.loaded = true;
                    body.className = body.className.replace('not--loaded', 'is--loaded');
                    setTimeout(() => {
                        body.className += ' loading-animation-done'
                    }, 200);
                }

                AOS.init({
                    disable: function () {
                        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
                    }
                });
            })
        }
    }).$mount('#app');
}
